<template>
  <div>
    <NavBar
    style="max-height: 50px;margin-bottom: 10px;"
    :username="currentUserName"
    :pageName="pageName"
    ></NavBar>
    <v-app>
      <div style="background-color: #f5faff; height: 93vh !important;">
        <v-row cols="12">
        <v-col cols="2">
          <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
        </v-col>
          <v-col cols="10" style="overflow:scroll; height:90vh">
            <div style="margin:10px 20px 20px 50px">
            <div
              style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              vertical-align: middle;
              "
              >
              <!-- <v-row style="margin-left: 5px"> -->
                <div style="width: 30%;margin-top: 25px;" align="left">
                <span style="font-weight: 800; font-size: 12pt; padding-left: 5px;
                text-transform: uppercase;   letter-spacing: 1px;">
                  <h3>Suggestions</h3>
                </span>
                </div>  
                <!-- <span style=" margin-left: 80%"> -->
                  
                    <div style="border-style: solid; border-color: white; border-radius: 8px; border-width: 0.1em;">
                      <span style="font-size: 26pt; display: flex; justify-content: space-between;align-items: end;">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }" style="display: flex; justify-content: end; align-items: center;">
                            <v-btn append rounded color="primary" v-on="on" style="height: 35px; margin-top:2vh; background-color: #1467BF;" @click="addSuggestionFunction">
                              <span style="font-size: 18pt;"> + </span>
                              <span style="font-weight: 600;margin-top:2%">  Add Suggestion</span>
                            </v-btn>
                          </template>
                          <span>Add Suggestion</span>
                        </v-tooltip>
                      </span>
                    </div>
                  
                <!-- </span> -->

              <!-- </v-row> -->
              </div>
              <div v-if="!isLoading" style="overflow: scroll; overflow-x: hidden; margin-top: 2%; height:45px 0px">
                <table class="tableClass"  v-if="suggestion_list.length > 0">
                  <thead>
                    <tr>
                      <th class="text-left">Suggestion Name</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Availability Status</th>
                      <th class="text-left">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="suggestion in suggestion_list" :key="suggestion.suggestion_phonenumber">
                      <td align="left" class="suggestionTitleClass">
                        {{ suggestion.suggestion }}
                      </td>
                      <td align="left" class="suggestionDescriptionClass">
                        {{ suggestion.description }}
                      </td>
                      <td  align="left">
                        <v-switch
                            color = "primary"
                            :loading = "isEditLoading"
                            :disabled = "isDisabled"
                            dense
                            v-model = "suggestion.show_status"
                            @change="changeStatus(suggestion._id,suggestion.show_status)"
                        ></v-switch>
                      </td>
                      <td align="left">
                        <a
                          class="btnClass"
                          depressed
                          icon
                          @click="editSuggestion(suggestion._id)"
                          >
                          <img
                          src="https://s3iconimages.mymedicine.com.mm/edit.svg"
                          />
                        </a>
                        <a
                          class="btnClass"
                          depressed
                          icon
                          @click="deleteSuggestion(suggestion._id)"
                          >
                          <img
                          src="https://s3iconimages.mymedicine.com.mm/delete.svg"
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else>
                  <p style="margin: 20px;">
                    No Data Found!
                  </p>
                </div>
              </div>
              <v-progress-circular
                  indeterminate
                  color="primary"
                  v-else
              ></v-progress-circular>
              <div v-if="suggestion_list && !isLoading && pagination_list > 1">
                <v-pagination
                  circle
                  v-model="page"
                  :length="pagination_list"
                  :total-visible="7"
                  @input="findPage(page)"
                ></v-pagination>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";

export default {
  name: "ViewAllSuggestion",
  data() {
    return {
      suggestion_list: "",
      isEditLoading: false,
      isDisabled: false,
      user_id: "",
      currentUser: "",
      currentUserName: "",
      pageName: "",
      isLoading : false,
      pagination_list:0,
      page:1,
    };
  },
  mounted() {
    this.pageName = "ViewAllSuggestions";
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        page : 1,
        limit : 10
      };
      this.isLoading = true;
      axios.post(process.env.VUE_APP_BACKEND_URL + "/adminAllSuggestionsPage", data)
      .then((successResponse) => {
        this.currentUserName = successResponse.data.admin_data.admin_name;
        this.suggestion_list = successResponse.data.data.docs;
        if(this.suggestion_list.length > 0)
        {
          this.suggestion_list.forEach((x) => {
            x.show_status = x.show_status == 'ACTIVE' ? true : false;
          });
          this.pagination_list = successResponse.data.data.pages;
        }
        this.isLoading = false;
      })
      .catch((errorResponse) => {
        this.isLoading = false;
        this.isLoading = false;
        console.log(errorResponse);
        if (errorResponse.response.status == 401) {
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name: "AdminLogin",
          });
        }else if(errorResponse.response.status == 402){
          window.alert("Admin is Inactive...");
        } else {
          window.alert("Something went wrong...");
        }
      });
    }
  },
  components: {
    NavBar,SideBar,
  },
  methods: {
    deleteSuggestion(suggestion_id) {
      var delete_body = {
        suggestionId: suggestion_id,
        token: this.currentUser,
        typeOfUser: "ADMIN"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/deleteSuggestion",delete_body)
      .then((deleteResponse) =>{
        window.alert("Deleted");
        this.$router.go();
      })
      .catch((deleteError) =>{
        console.log(deleteError);
      });
    },
    changeStatus(suggestion_id,show_status){
      this.isEditLoading = 'error';
      this.isDisabled = true;
      var updateData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        suggestionId: suggestion_id,
        activeStatus: show_status ? 'ACTIVE' : 'INACTIVE'
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/updateSuggestionAvailableStatus",updateData)
      .then((updateResponse) => {
        this.isEditLoading = false;
        this.isDisabled = false;
      })
      .catch((updateError)=>{
        window.alert("Error while updating status");
        this.isEditLoading = false;
        this.isDisabled = false;
      });
    },
    findPage(pageNum){
      this.isLoading = true
      var getSuggestionData = {
          token: this.currentUser,
          typeOfUser: "ADMIN",
          page: pageNum,
          limit: 10,
        };
       axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getAllSuggestions",
          getSuggestionData
          )
        .then((getSuggestionListResponse) => {
          this.suggestion_list = getSuggestionListResponse.data.data.docs;
          this.suggestion_list.forEach((x) => {
            x.show_status = x.show_status == 'ACTIVE' ? true : false;
          });
          this.pagination_list = getSuggestionListResponse.data.data.pages;
          this.isLoading = false
        })
        .catch((getSuggestionListError) => {
          this.isLoading = false
          if (getSuggestionListError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "AdminLogin",
            });
          } else {
            window.alert("Something went wrong...");
          }
        });
    },
    addSuggestionFunction() {
      this.$router.push({
        name: "CreateSuggestion",
      });
    },
    gotoLoginPage() {
      var deleteData = {
        idToken: this.currentUser,
      };
      axios
      .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
      .then((deleteResponse) => {
        this.$cookies.remove("token");
      })
      .catch((error) => {
      });
      localStorage.clear();
      this.$router.push({
        name: "AdminLogin",
      });
    },
    editSuggestion(suggestion_id) {
      this.$router.push({
        path: "editsuggestion/" + suggestion_id,
      });
    },
  },
};
</script>

<style scoped>
th {
  border: 0px;
  /* padding: 10px; */
}
td {
  font-size: 8pt !important;
  font-weight: 700 !important;
  height: 14px;
  padding-left: 5px;

}
th {
  background-color: #1467bf;
  line-height: 19px;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500 !important;
  padding-left: 5px;
  text-transform: capitalize;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 30px;
}
tr {
  border-bottom: #d4efff 1px solid !important;
  height: 15px;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
  margin-left: 2px;

}
/* table tr:first-child th:first-child {
    border-top-left-radius: 5px;
} */

table tr:first-child th:last-child {
    border-top-right-radius: 5px;
}
table > tr > td:last-child {
  white-space: nowrap;
}
.btnClass {
  background-color: transparent;
  margin: 5px;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#facebook{
  display: none;
}
.v-input--selection-controls{
  margin-top: 2px !important;
  padding-top: 10px !important;
  padding-left: 10px !important;
}
.v-input--dense>.v-input__control>.v-input__slot{
  margin-bottom: 0px !important;
}
.v-application {
    line-height: 0.4;
}
.suggestionTitleClass {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.suggestionDescriptionClass {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

</style>